export const HSRConeSourceArray = [
  { label: 'Any Source', value: 'all', description: '' },
  {
    label: 'Echo of War',
    value: 'Echo of War'
  },
  {
    label: 'Event Warp (limited)',
    value: 'Event Warp (limited)'
  },
  {
    label: 'Forgotten Hall Store',
    value: 'Forgotten Hall Store'
  },
  {
    label: "Herta's Store",
    value: "Herta's Store"
  },
  {
    label: 'Mission Reward',
    value: 'Mission Reward'
  },
  {
    label: 'Nameless Glory',
    value: 'Nameless Glory'
  },
  {
    label: 'Stellar Warp',
    value: 'Stellar Warp'
  }
];
